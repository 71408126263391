import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/userManagement',
        name: 'UserManagement',
        component: () => import('../views/user/UserManagement.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/masterList',
        name: 'MasterList',
        component: () => import('../views/master/MasterList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/senderList',
        name: 'SenderList',
        component: () => import('../views/order/SenderList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/orderList',
        name: 'OrderList',
        component: () => import('../views/order/OrderList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/stationList',
        name: 'StationList',
        component: () => import('../views/station/StationList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/stationShipList',
        name: 'StationShipList',
        component: () => import('../views/station/StationShipList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/unlockDoorLog',
        name: 'UnlockDoorLog',
        component: () => import('../views/station/UnlockDoorLog.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/userCheckLog',
        name: 'UserCheckLog',
        component: () => import('../views/station/UserCheckLog.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/LightBarList',
        name: 'LightBarList',
        component: () => import('../views/station/LightBarList.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/roleManager',
        name: 'RoleManager',
        component: () => import('../views/menu/RoleManager.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/menuManager',
        name: 'MenuManager',
        component: () => import('../views/menu/MenuManager.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: '/siteManager',
        name: 'SiteManager',
        component: () => import('../views/site/siteManager.vue'),
        meta: {
          keepAlive: true
        }
      }
      
      
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
