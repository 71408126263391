import Vue from 'vue'
import Vuex from 'vuex'
// import "babel-polyfill";

let runType = (process.env.VUE_APP_TYPE || "").toLowerCase();
let title = runType == 'dx' ? "合一驿站" : "一城集配";

// document.title = `${title}管理系统`;

Vue.use(Vuex)
// console.log(process.env)
const store = new Vuex.Store({
    state: {
      httpDisabled: false,
      copyrightShow: false,
      hasMenuList: [],
      runType: runType,
      title: title
    },
    mutations: {
      change(state, bool) {
        state.httpDisabled = bool ? bool : false;
      },
      changeCopyright(state, bool) {
        state.copyrightShow = bool ? bool : false;
      },
      updateHasMenuList(state, arr) {
        state.hasMenuList = arr;
      }
    },
    actions: {
      
    }
})

export default store