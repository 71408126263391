import axios from 'axios';
import bus from './bus';

function axiosHttp() {
  let instance = axios
  if (process.env.NODE_ENV === "development") {
    instance.defaults.baseURL = '/api';
  } else {
    if ((process.env.VUE_APP_TYPE || "").toLowerCase() == "dx") {
      instance.defaults.baseURL = '/api';
    }
  }

  instance.interceptors.request.use(function (config) {
    const sm_oa_auth = sessionStorage.getItem('sm_oa_auth')
    config.headers['Authorization'] = 'Bearer ' + sm_oa_auth;
    var reqData = config.data;
    if (Object.prototype.toString.call(config.data) == '[object FormData]') {

    } else if (typeof config.data == 'object') {
      for (var i in reqData) {
        config.data[i] = reqData.replace(/^(%20)*|(%20)*$/g, '').trim();
      }
    } else {
      if (!!reqData) {
        config.data = reqData.split('&').map((item) => {
          return item.split('=').map((childItem) => {
            return childItem.replace(/^(%20)*|(%20)*$/g, '').trim()
          }).join('=')
        }).join('&');
      } else {
        config.data = reqData;
      }
      // console.log(config.data, '结果')
    }



    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(function (response) {
    // console.log(response.data, '结果')
    if (response.data.code && response.data.code == 99) {
      bus.$emit('loginTimeout');
      sessionStorage.removeItem("userInfo");
      location.href = '/login';
    }
    return response;
  }, function (error) {
    return Promise.reject(error);
  });
  return instance;
}

export default axiosHttp
