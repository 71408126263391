<template>
  <div class="sidebar">
    <el-menu :default-active="onRoutes" background-color="#3D4454" text-color="#FFFFFF" active-text-color="#78CDF4"
      unique-opened router @select="menuSelect">
      <template v-for="(item, tIndex) in sideList">
        <template v-if="item.subs && item.subs.length > 0">
          <el-submenu :index="String(tIndex)" :key="String(tIndex)">
            <template slot="title">
              <i class="default_icon_menu">
                <img :src="require('../assets/menu' + (tIndex <= 5 ? tIndex + 1 : '') + '.png')" alt="" />
              </i>
              <span slot="title">{{ item.menu_name }}</span>
            </template>
            <el-menu-item v-for="(subItem, i) in item.subs" :key="i" :index="handleMenuUrl(subItem.menu_url)">
              <i style="padding-left: 10px;"></i>
              <span slot="title">{{ subItem.menu_name }}</span>
            </el-menu-item>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item v-show="item.menu_url" :index="handleMenuUrl(item.menu_url)" :key="String(tIndex)">
            <i class="default_icon_menu">
              <img :src="require('../assets/menu' + (tIndex <= 5 ? tIndex + 1 : '') + '.png')" alt="" />
            </i>
            <span slot="title">{{ item.menu_name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '../common/bus';
import global from "../common/global"
import { decodeUserInfo, handleTreeData } from "../common/tools"
export default {
  data() {
    return {
      userInfo: decodeUserInfo(sessionStorage.getItem('userInfo'))
    }
  },
  inject: ['reload'],
  computed: {
    onRoutes() {
      return this.$route.path;
    },
    handleMenuUrl() {
      return (val) => {
        var strVal = String(val)
        return strVal.indexOf('/') == 0 ? strVal : '/' + strVal
      }
    },
    sideList() {
      let menuList = this.$store.state.hasMenuList;      
      let arr = handleTreeData(menuList, {
        parentId: "menu_parentId",
        id: "menu_id",
        children: "subs"
      });
      // console.log(arr, 'arr')
      return arr.filter(item => item.subs && item.subs.length > 0);
    }
  },
  created() {

  },
  methods: {
    menuSelect(index, indexPath) {
      let url = index.indexOf("/") == 0 ? index : `/${index}`;
      if (this.$route.path == url && !this.$route.meta.keepAlive) this.reload();
    }
  }
}
</script>

<style scoped lang='less'>
.sidebar {
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  width: 280px;
  background-color: #3D4454;

  .el-menu {
    width: 100%;
    text-align: left;
  }

  .default_icon_menu {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 9px;

    img {
      height: 20px;
      vertical-align: top;
    }
  }
}
</style>
