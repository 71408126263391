<template>
  <div class="wrapper">
    <v-head></v-head>
    <v-sidebar style="bottom:0;"></v-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }" style="bottom: 0">
      <v-tags></v-tags>
      <div class="content" :style="{ bottom: $store.state.copyrightShow && $store.state.acfsmcShow ? '40px' : '0' }">
        <transition name="move" mode="out-in">
          <template v-if="!!$route.meta.keepAlive">
            <keep-alive>
              <router-view :key="$route.path"></router-view>
            </keep-alive>
          </template>
          <template v-if="!$route.meta.keepAlive && isRouterAlive">
            <router-view :key="$route.path" v-if="!$route.meta.keepAlive && isRouterAlive"></router-view>
          </template>
        </transition>
      </div>
    </div>
    <!-- <div style="position: absolute;bottom :0;left: 0;width: 100%;font-size: 0;background: rgba(0,0,0,0.5)" v-if="false">
      <marquee behavior="scroll" direction="left" style="line-height: 30px;font-size: 14px;color: white">温馨提醒</marquee>
    </div> -->
  </div>
</template>

<script>
import vHead from "../components/Header.vue";
import vSidebar from "../components/Sidebar.vue";
import vTags from "../components/Tags.vue";
import bus from "../common/bus";
import global from "../common/global";
import { encodeUserInfo, decodeUserInfo, testJJM } from "../common/tools";
export default {
  data() {
    return {
      isRouterAlive: true,
      alertShow: false,
      userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
      ruleForm: {
        username: localStorage.getItem("rememberAccount")
          ? JSON.parse(testJJM(localStorage.getItem("rememberAccount"), 2))[
          "username"
          ]
          : "",
        password: localStorage.getItem("rememberAccount")
          ? JSON.parse(testJJM(localStorage.getItem("rememberAccount"), 2))[
          "password"
          ]
          : ""
      },
      collapse: false,
      showLoginTimeout: false,
      logTime: null,
      curTimestamp: Date.now(),
      homeAlive: true
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags
  },
  created() {
    var that = this;
    //  this.getPersonList(); //获取所有收派员
    //  this.sidebarStatus();
    window.addEventListener("resize", function () {
      that.sidebarStatus();
    });
    bus.$on("collapse", msg => {
      this.collapse = msg;
    });

    this.timeoutMethod();
    this.resetLoginTime();

    // console.log(this.$route);

  },
  mounted() {
    var username = this.ruleForm.username;
    var userInfo = this.userInfo;
    // if (userInfo.branches != '603283') this.$store.commit('changeCopyright', true)
    // else this.$store.commit('changeCopyright', false)
    this.getHasMenuList();
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  methods: {
    getHasMenuList() {
      this.$axios
        .post("/oa/menu/getListByToken", this.$qs.stringify({}))
        .then(({ data }) => {
          let resData = data.data || {};

          let hasMenuList = (resData.menuList || []).map(item => item.router);
          let menuList = JSON.parse(JSON.stringify(global.totalMenuList)).filter(item => (hasMenuList.indexOf(item.menu_url) != -1 || item.menu_url == ""));

          this.$store.commit('updateHasMenuList', menuList);
        })
    },
    reload() {
      var that = this;
      that.isRouterAlive = false;
      this.$nextTick(() => {
        that.isRouterAlive = true;
      });
    },
    resetLoginTime() {
      var that = this;
      document.body.addEventListener("mousemove", that.handle, false);
      document.body.addEventListener("touchmove", that.handle, false);
    },
    handle() {
      var that = this;
      var curTimestamp = that.curTimestamp;
      var nowTimestamp = Date.now();
      //  console.log(nowTimestamp - curTimestamp)
      if (nowTimestamp - curTimestamp >= 200) {
        if (!that.showLoginTimeout && !that.alertShow) that.timeoutMethod();
        that.curTimestamp = nowTimestamp;
        if (sessionStorage.getItem("sm_timeout_status"))
          sessionStorage.setItem("sm_timeout_status", nowTimestamp);
      }
    },

    timeoutMethod(time) {
      // console.log('重置')
      var that = this;
      if (that.logTime) clearTimeout(that.logTime);

      that.logTime = setTimeout(() => {
        if (that.logTime) clearTimeout(that.logTime);
        that.showLoginTimeout = true;
        if (!this.alertShow) that.open();
        sessionStorage.removeItem("sm_timeout_status");
      }, global.loginTimeout * 60 * 1000);
    },
    open() {
      // console.log(this.logTime);
      if (this.logTime) clearTimeout(this.logTime);
      this.alertShow = true;
      this.$alert("登录失效，请重新登录", "温馨提醒", {
        confirmButtonText: "确定",
        showClose: false,
        callback: action => {
          this.alertShow = false;
          this.logoOut();
        }
      });
    },
    sidebarStatus() {
      var w = document.body.clientWidth;
      if (w <= 979) {
        this.collapse = true;
      } else {
        this.collapse = false;
      }
    },
    logoOut() {
      sessionStorage.removeItem("ms_username");
      this.$router.push("/login");
    },
    getPersonList() {
      this.$axios
        .post(
          "/back/customer/findCustomerNameAndIdForOms",
          this.$qs.stringify({ branches: this.userInfo.branches })
        )
        .then(res => {
          global.currentSitePersonList = res.data.data;
          this.$eventBus.$emit("personList", res.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  destroyed() {
    if (this.logTime) clearTimeout(this.logTime);
    document.body.removeEventListener("mousemove", this.handle);
    document.body.removeEventListener("touchmove", this.handle);
  }
};
</script>

<style scoped lang="less">
.content-box {
  position: absolute;
  left: 280px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow: hidden;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  background: #F5F6F8;
  text-align: left;
}

.content {
  position: absolute;
  top: 53px;
  left: 0;
  bottom: 0;
  padding: 10px;
  width: 100%;
}

.all-timeout-login {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 600;

  .all-timeout-login-container {
    width: 200px;
    border-radius: 10px;
    background: white;
    text-align: center;
  }
}
</style>
