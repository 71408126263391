import global from './global';
import CryptoJS from 'crypto-js'

var dHandle = function (num) {
  if (num < 10) {
    return "0" + num;
  } else {
    return num;
  }
};

var _isEmpty = (val) => {
  if (val == null) return true;
  if (typeof val === 'boolean') return val;
  if (typeof val === 'number') return false;
  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }
  return false;
}

var _isNotEmpty = (val) => {
  return !_isEmpty(val);
}

var _requestParams = (data, show) => {
  var obj = data ? data : {};
  var showVal = typeof show == 'boolean' ? show : true;
  var returnVal = {}
  for (var i in obj) {
    if (typeof obj[i] == 'object') {
      if (isEmpty(obj[i] && obj[i].value)) {
        returnVal[i] = isEmpty(obj[i] && obj[i].default) ? '' : obj[i].default;
      } else {
        returnVal[i] = obj[i].value;
      }
    } else {
      if (isEmpty(obj[i])) {
        if (showVal) returnVal[i] = '';
      } else {
        returnVal[i] = obj[i];
      }
    }
  }
  return returnVal;
}

var _currentDate = (ob) => {
  var obj = ob ? ob : {};
  var resultArr = [];
  var weekArr = ['星期天', '星期一', '星期二', '星期三', '星期一四', '星期五', '星期六'];
  var ago = obj.ago ? obj.ago * 24 * 3600 * 1000 : 0;
  var date = obj.timestamp ? new Date(new Date(obj.timestamp).getTime() - ago) : new Date(new Date().getTime() - ago);
  var dateFormat = obj.dateFormat ? obj.dateFormat : 'yyyy-MM-dd HH:mm:ss';
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hour = date.getHours();
  var minute = date.getMinutes();
  var second = date.getSeconds();
  var week = weekArr[date.getDay()];
  var dateFormatArr = dateFormat.split(' ');
  for (var i = 0; i < dateFormatArr.length; i++) {
    if (dateFormatArr[i].indexOf('yyyy') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/yyyy/g, year);
    }

    if (dateFormatArr[i].indexOf('MM') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/MM/g, dHandle(month));
    }

    if (dateFormatArr[i].indexOf('dd') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/dd/g, dHandle(day));
    }

    if (dateFormatArr[i].indexOf('HH') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/HH/g, dHandle(hour));
    }

    if (dateFormatArr[i].indexOf('mm') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/mm/g, dHandle(minute));
    }

    if (dateFormatArr[i].indexOf('ss') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/ss/g, dHandle(second));
    }

    if (dateFormatArr[i].indexOf('E') != -1) {
      dateFormatArr[i] = dateFormatArr[i].replace(/E/g, week);
    }
  }
  // console.log(dateFormatArr);
  return dateFormatArr.join(' ');
}

var _timeSToC = (mSecond) => {
  if (!mSecond || mSecond == 0) return '0';
  var second = (mSecond - mSecond % 1000) / 1000;
  var _ms = mSecond % 1000
  var _m = (second - Number(second) % 60) / 60;
  var _s = second - _m * 60;
  console.log(_m, _s)
  var str = (_m > 0 ? _m + '分' : '') + (_s > 0 ? dHandle(_s) + '秒' : '') + (_ms > 0 ? dHandle(_ms) + '毫秒' : '')
  return str;
}

var _scanTypeFilter = (cellValue) => {
  var list = global.scanTypeList;
  if (!cellValue) return "无法识别";
  for (var i in list) {
    if (list[i].value == cellValue) {
      return list[i].label;
    }
  }
}

var _brandCodeFilter = (cellValue) => {
  var list = global.brandCodeList;
  if (!cellValue) return "无法识别";
  for (var i in list) {
    if (list[i].value == cellValue) {
      return list[i].label;
    }
  }
}

var _arrDelItem = (obj) => {
  if (obj.value) {
    for (var i in obj.arr) {
      if (obj.arr[i] == obj.value) {
        obj.arr.splice(i, 1);
      }
    }
    return obj.arr;
  } else if (!_isEmpty(obj.index)) {
    obj.arr.splice(obj.index, 1);
    return obj.arr
  }
}

var _amountDecimal = (num, b) => {
  var bit = _isEmpty(b) || isNaN(b) ? 2 : b;
  if (bit <= 0) return num;
  if (isNaN(num)) return num;
  var numStr = num.toString();
  var s1 = numStr.split('.')[1];
  // console.log(numStr.split('.')[0] + '.' + lNum)
  return num.toFixed(Math.max(s1 ? s1.length : 0, bit));
}

var letter = 'qwertyuiopasdfghjklzxcvbnm'

var encode = (val) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(val));
}
var decode = (val) => {
  return CryptoJS.enc.Base64.parse(val).toString(CryptoJS.enc.Utf8)
}
var _testJM = (str, type) => {
  try {
    var ranNum = letter[parseInt(Math.random() * letter.length)]
    var chrCode = ranNum.charCodeAt();
    var bsStr = encode(str)
    // console.log(bsStr, chrCode, ranNum, chrCode%bsStr.length)
    var index = chrCode % bsStr.length
    var returnStr = ranNum.toUpperCase() + bsStr.substring(0, index) + chrCode + ranNum + bsStr.substring(index)
    return returnStr
  } catch (e) {
    return type == 2 ? '{}' : ''
  }
}

var _testJJM = (str, type) => {
  try {
    var ranNum = str[0].toLowerCase()
    var chrCode = ranNum.charCodeAt();
    var strLen = str.length - String(chrCode).length - 2
    var index = chrCode % strLen
    var bsStr = str.substring(1, index + 1) + str.substring(index + String(chrCode).length + 2)
    // console.log(bsStr,ranNum, chrCode, strLen, index, decode(bsStr))
    return decode(bsStr)
  } catch (e) {
    return type == 2 ? '{}' : ''
  }
}

var _encodeUserInfo = (parmas) => {
  try {
    var s = JSON.stringify(parmas)
    return _testJM(s)
  } catch (e) {
    return _testJM(String(parmas))
  }
}

var _decodeUserInfo = (str) => {
  var s = _testJJM(str)
  try {
    var rs = JSON.parse(s)
    return rs
  } catch (e) {
    return s
  }
}

var _deepCopy = function (source) {
  function clone(source) {
    var sourceCopy = Array.prototype.isPrototypeOf(source) ? [] : {};
    for (var item in source) sourceCopy[item] = typeof source[item] === 'object' ? clone(source[item]) : source[item];
    return sourceCopy;
  }
  return clone(source);
}

var _handleTreeData = (data, propsParams) => {
  var props = Object.assign({
    parentId: 'parentId',
    id: 'deptId',
    children: 'children'
  }, propsParams ? propsParams : {})

  function arrayToTree(arr, parentId) {
    let temp = [];
    let treeArr = arr;
    treeArr.forEach((item, index) => {
      if (item[props.parentId] == parentId) {
        if (arrayToTree(treeArr, treeArr[index][props.id]).length > 0) {
          treeArr[index][props.children] = arrayToTree(treeArr, treeArr[index][props.id]);
        }
        temp.push(treeArr[index]);
      }
    });
    return temp;
  }
  return arrayToTree(data, 0);
}

export var isEmpty = _isEmpty;
export var isNotEmpty = _isNotEmpty;
export var requestParams = _requestParams;
export var currentDate = _currentDate;
export var timeSToC = _timeSToC;
export var scanTypeFilter = _scanTypeFilter;
export var brandCodeFilter = _brandCodeFilter;
export var arrDelItem = _arrDelItem;
export var amountDecimal = _amountDecimal;
export var testJM = _testJM;
export var testJJM = _testJJM;
export var encodeUserInfo = _encodeUserInfo;
export var decodeUserInfo = _decodeUserInfo;
export var deepCopy = _deepCopy;
export var handleTreeData = _handleTreeData;

export default {
  isEmpty: _isEmpty,
  isNotEmpty: _isNotEmpty,
  requestParams: _requestParams,
  currentDate: _currentDate,
  timeSToC: _timeSToC,
  scanTypeFilter: _scanTypeFilter,
  brandCodeFilter: _brandCodeFilter,
  arrDelItem: _arrDelItem,
  amountDecimal: _amountDecimal,
  testJM: _testJM,
  testJJM: _testJJM,
  encodeUserInfo: _encodeUserInfo,
  decodeUserInfo: _decodeUserInfo,
  deepCopy: _deepCopy,
  handleTreeData: _handleTreeData
}
