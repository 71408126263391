<template>
  <div class="tags" v-if="showTags">
    <el-scrollbar :native="false" horizontal>
      <ul class="tags-ul">
        <li class="tags-li" v-for="(item, index) in tagsList" :class="{ 'active': isActive(item.path) }" :key="index">
          <router-link :to="item.path" class="tags-li-title">{{ item.path == '/' ? '首页' : item.title }}</router-link>
          <span class="tags-li-icon" @click="closeTags(index)">
            <i class="el-icon-close"></i>
          </span>
        </li>
      </ul>
    </el-scrollbar>
    <div class="tags-close-box flex-column-center-container">
      <el-dropdown @command="handleTags">
        <el-button type="primary">标签选项
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import bus from "../common/bus";
import global from "../common/global";
export default {
  data() {
    return {
      tagsList: [],
      //滚动区域层class
      wrapClass: {
        wrapClass: true
      },
      wrapStyle: {
        fontWeight: 800
      },

      //滚动整体区域的class
      viewClass: {
        viewClass: true
      },
      viewStyle: {
        border: '1px solid #000000',
        boxSizing: 'border-box'
      },
      // native属性：如果为true就不显示el的bar，也就是el模拟出来的滚动条，如果为false就显示模拟的滚动条
      native: false,
      // tag 渲染出来的标签
      tag: 'section',
      // noresize 是否刷新滚动条大小
      noresize: true
    };
  },
  methods: {
    isActive(path) {
      return path === this.$route.path;
    },
    // 关闭单个标签
    closeTags(index) {
      const delItem = this.tagsList.splice(index, 1)[0];
      const item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        delItem.path === this.$route.path && this.$router.push(item.path);
      } else {
        this.$router.push("/");
      }
    },
    // 关闭全部标签
    closeAll() {
      this.tagsList = [];
      this.$router.push("/");
    },
    // 关闭其他标签
    closeOther() {
      const curItem = this.tagsList.filter(item => {
        return item.path === this.$route.path;
      });
      this.tagsList = curItem;
    },
    // 设置标签
    setTags(route) {
      const isExist = this.tagsList.some(item => {
        return item.path === route.path;
      });
      let totalMenuList = this.$store.state.hasMenuList; 

      var title = "";
      for (var i in totalMenuList) {
        if (totalMenuList[i].menu_url == route.path) {
          title =totalMenuList[i].menu_name;
        }
      }
      
      !isExist &&
        this.tagsList.push({
          title: title ? title : route.meta.title,
          path: route.path
        });
    },
    handleTags(command) {
      command === "other" ? this.closeOther() : this.closeAll();
    }
  },
  computed: {
    showTags() {
      return this.tagsList.length >= 0;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.setTags(newValue);
    }
  },
  created() {

  }
};
</script>


<style scoped lang="less">
.tags {
  position: relative;
  height: 53px;
  overflow: hidden;
  background: #3D4454;
  padding-right: 150px;
}

.tags-ul {
  box-sizing: border-box;
  width: 100%;
  height: 53px;
  white-space: nowrap;

  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
  }
}

.tags-li {
  display: inline-block;
  margin: 0 8px;
  border-radius: 3px;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  line-height: 1.3;
  background: #fff;
  padding: 6px 3px 6px 13px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  background-color: #5873D8;
  color: #fff;
}

.tags-li-title {
  float: left;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li-icon {
  margin-right: 5px;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding-top: 1px;
  text-align: center;
  width: 110px;
  height: 100%;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .el-button {
    padding: 6px 13px;
    line-height: 1.3;
  }
}
</style>
