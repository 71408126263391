<template>
  <div class="header">
    <router-link class="header-left flex-column-center-container" to="" :title="$store.state.title" style="color: #FFFFFF;">
      <img :src="require(`../assets/logo${$store.state.runType}.png`)" :alt="$store.state.title" class="logo-ico" />
    </router-link>
    <div class="header-right flex-center-container">
      <!--<router-link to="/dataGraph" :title="$store.state.title">
        <el-tooltip effect="dark" content="图表" placement="bottom">
          <img class="header-icon" src="../assets/data-icon.png" alt="图表" />
        </el-tooltip>
      </router-link>-->
      <div class="btn-fullscreen" @click="handleFullScreen">
        <el-tooltip effect="dark" :content="fullscreen ? `取消全屏` : `全屏`" placement="bottom">
          <img class="header-icon" src="../assets/fullscreen-icon.png" alt="全屏" />
        </el-tooltip>
      </div>
      <el-tooltip effect="dark" content="消息" placement="bottom">
        <img class="header-icon" src="../assets/notice.png" alt="消息" />
      </el-tooltip>
      <el-dropdown class="user-name" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <div>{{ userInfo.nickName }}</div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import bus from "../common/bus";
import global from "../common/global";
import {
  decodeUserInfo,
  isEmpty
} from "../common/tools";
export default {
  data() {
    return {
      fullscreen: false,
      userInfo: decodeUserInfo(sessionStorage.getItem("userInfo")),
      headList: []
    };
  },
  inject: ['reload'],
  computed: {},
  watch: {
    $route(newValue, oldValue) {

    }
  },
  created() {

  },
  methods: {
    handleCommand(command) {
      if (command == "loginout") {
        sessionStorage.removeItem("userInfo");
        this.$store.commit('updateHasMenuList', []);
        this.$store.commit('updateStationIds', "");
        this.$router.push("/login");
      }
    },
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    }
  }
};
</script>
<style scoped lang='less'>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;

  .header-left {
    width: 280px;
    // background-color: #5873D8;
    height: 100%;
    text-align: left;
    cursor: pointer;
    align-items: flex-start;

    img.logo-ico {
      height: 80px;
      margin-left: 20px;
    }
  }

  .header-right {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-right: 30px;
  }

  .header-icon {
    height: 25px;
    vertical-align: top;
    cursor: pointer;
    margin: 0 17px;
  }

  .avatar {
    width: 44px;
    height: 44px;
    line-height: 44px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #F5F6F8;
    cursor: pointer;
    margin-left: 21px;
  }
}
</style>
