import Vue from 'vue'
import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
import App from './App.vue'
import router from './router'
import store from './store'
import qs from 'qs'
import ElementUI from 'element-ui'
import './assets/style/theme.css';
import axiosHttp from './common/axiosHttp'
import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);
Vue.use(ElementUI, {
  size: 'medium'
});

Vue.config.productionTip = false
Vue.prototype.$axios = axiosHttp();
Vue.prototype.$qs = qs;
Vue.prototype.$eventBus = Vue.prototype.$eventBus || new Vue();

Vue.prototype.$md5 = (str) => {
  return CryptoJS.MD5(str).toString()
};
Vue.prototype.$base64 = {
  encode: (val) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(val));
  },
  decode: (val) => {
    return CryptoJS.enc.Base64.parse(val).toString(CryptoJS.enc.Utf8)
  }
}

let aesKey = "ztd123!@ztd123!@";

Vue.prototype.$aes = {
  encode: (val) => {
    var key = CryptoJS.enc.Utf8.parse(aesKey);
    var srcs = CryptoJS.enc.Utf8.parse(val);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  decode: (val) => {
    var key = CryptoJS.enc.Utf8.parse(aesKey);
    var decrypt = CryptoJS.AES.decrypt(val, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    var originalText = CryptoJS.enc.Utf8.stringify(decrypt).toString()
    return originalText
  }
}

Vue.prototype.encrypted = (data, publicKey) => {
  var encrypt = new JSEncrypt();
  const pubkey = `
  -----BEGIN PUBLIC KEY-----
  ${publicKey ? publicKey : 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy8BAqbCFmzzXlV8BGalGS0MJ+cQh29u9qEJylaZewntGvkggLdKmMN2sigavhlNO3jBpG8XOHuO1hfAU0RD/LvrkOTEbDRVeboawnLo9vOlaxYhZT6non9Ofp1y/kEfGlOvxm3HR1zeaX8oRuqqj8Esb4Mu9U4feMVWvFOYZP53aCynC6XoLhXJKg7yCrxR3ZY9lYt4KEpO33xVayrgM7/4PTADscq3MumNQUt+s0v/+9y8S9tOIc/OriAQD+S1B64uTTNuVTObqK44z/zZ7f/7boMVl290A4LehChA4BKcw5JIVhNjURExPNrLDGbF0HURn+JIMaABuFsLaeU4vFwIDAQAB'}
  -----END PUBLIC KEY-----`;
  encrypt.setPublicKey(pubkey);
  return encrypt.encrypt(data);
}


router.beforeEach((to, from, next) => {
  // console.log(to, from)
  const role = sessionStorage.getItem('ms_username');
  const smTimeoutStatus = sessionStorage.getItem('sm_timeout_status')
  const userInfo = sessionStorage.getItem('userInfo')
  let whetherLogin = false;
  try {
    var users = JSON.parse(userInfo)
    whetherLogin = true;
  } catch (e) {
    whetherLogin = false;
  }

  if (to.path == '/dataGraph') {
    next();
  } else if ((whetherLogin || !role || (!smTimeoutStatus || (smTimeoutStatus && Date.now() - Number(smTimeoutStatus) >= global.loginTimeout * 60 * 1000))) && to.path !== '/login') {
    next('/login');
  } else if (to.meta.permission) {
    role === 'admin' ? next() : next('/403');
  } else {
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
