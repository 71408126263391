export default {
  totalMenuList: [
    /******************************/
    {menu_parentId: '0', menu_id: '1', menu_url: '', menu_name: '网点管理'},
    {menu_parentId: '1', menu_id: '1-1', menu_url: '/siteManager', menu_name: '网点管理'},
    /******************************/
    {menu_parentId: '0', menu_id: '2', menu_url: '', menu_name: '用户管理'},
    {menu_parentId: '2', menu_id: '2-1', menu_url: '/userManagement', menu_name: '用户管理'},
    /******************************/
    {menu_parentId: '0', menu_id: '3', menu_url: '', menu_name: '管理员管理'},
    {menu_parentId: '3', menu_id: '3-1', menu_url: '/masterList', menu_name: '管理员列表'},
    {menu_parentId: '0', menu_id: '4', menu_url: '', menu_name: '快递管理'},
    {menu_parentId: '4', menu_id: '4-1', menu_url: '/senderList', menu_name: '寄件列表'},
    {menu_parentId: '4', menu_id: '4-2', menu_url: '/orderList', menu_name: '收件列表'},
    {menu_parentId: '0', menu_id: '5', menu_url: '', menu_name: '驿站管理'},
    {menu_parentId: '5', menu_id: '5-1', menu_url: '/stationList', menu_name: '驿站列表'},
    {menu_parentId: '5', menu_id: '5-2', menu_url: '/stationShipList', menu_name: '寄件码列表'},
    {menu_parentId: '5', menu_id: '5-3', menu_url: '/unlockDoorLog', menu_name: '开锁记录'},
    {menu_parentId: '5', menu_id: '5-4', menu_url: '/userCheckLog', menu_name: '查询记录'},
    {menu_parentId: '5', menu_id: '5-5', menu_url: '/LightBarList', menu_name: '亮灯记录'},

    {menu_parentId: '0', menu_id: '6', menu_url: '', menu_name: '菜单管理'},
    {menu_parentId: '6', menu_id: '6-1', menu_url: '/roleManager', menu_name: '角色管理'},
    {menu_parentId: '6', menu_id: '6-2', menu_url: '/menuManager', menu_name: '菜单管理'},

    




    /******************************/
  ],
  orderPattern: /^D[DB]\d{10,22}$|^TT\d{13}$|^D\d{11,23}$|^[MB]\d{11,14}$|^R02[TZ].{12,20}$|^G\d{11,17}$|^V\d{11}$|^YT\d{13}$|^YTD\d{10,20}$|^YTG\d{12}$|^\d{12,24}$|^E+[A-Za-z0-9]{12}$|^Z\d{11}$|^JT\d{13}$|^(PH|KQ|SA|KH|PQ)\d{11}$|^BG.{11}$|^BS.{11}$|^[A-Z]{2}.{11}$|^[A-Z]{2}.{11}$|^ZY+[A-Za-z0-9]{13}$/,
  msgCodeTime: 60, //短信验证码倒计时60秒
  scanLoginTime: 60, //扫描登录时间60秒
  newShowTime: 3, //公告显示3天
  loginTimeout: 30, //半个小时超时
  pageSize: 20,
  pageSizes: [20, 50, 200, 1000],
  currentSitePersonList: [],
  userInfo: {},
  branches: 0,
  listColor: "#d2eaf4",
  pickerOptions: {
    disabledDate(time) {
      var now = Date.now();
      var selectTime = time.getTime();
      return !(selectTime <= now && selectTime >= now - 30 * 24 * 3600 * 1000);
    },
    shortcuts: [{
        text: "今天",
        onClick(picker) {
          picker.$emit("pick", [new Date(), new Date()]);
        }
      },
      {
        text: "昨天",
        onClick(picker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit("pick", [date, date]);
        }
      },
      {
        text: "30天",
        onClick(picker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [date, new Date()]);
        }
      }
    ]
  },
  singleYearPickerOptions: {
    disabledDate(time) {
      var now = Date.now();
      var selectTime = time.getTime();
      return !(selectTime <= now && selectTime >= now - 12*30 * 24 * 3600 * 1000);
    },
    shortcuts: [{
        text: "今天",
        onClick(picker) {
          picker.$emit("pick", [new Date(), new Date()]);
        }
      },
      {
        text: "昨天",
        onClick(picker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit("pick", [date, date]);
        }
      },
      {
        text: "30天",
        onClick(picker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [date, new Date()]);
        }
      }
    ]
  },
  singlePickerOptions: {
    disabledDate(time) {
      var now = Date.now();
      var selectTime = time.getTime();
      return !(selectTime <= now && selectTime >= now - 30 * 24 * 3600 * 1000);
    },
    shortcuts: [{
        text: "今天",
        onClick(picker) {
          picker.$emit("pick", new Date());
        }
      },
      {
        text: "昨天",
        onClick(picker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit("pick", date);
        }
      },
      {
        text: "30天前",
        onClick(picker) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", date);
        }
      }
    ]
  },
  brandCodeList: [{
      value: "YTO",
      label: "圆通"
    },
    {
      value: "STO",
      label: "申通"
    },
    {
      value: "YD",
      label: "韵达"
    },
    {
      value: "BS",
      label: "百世"
    },
    {
      value: "TT",
      label: "天天"
    },
    {
      value: "ZT",
      label: "中通"
    },
    {
      value: "UC",
      label: "优速"
    },
    {
      value: "AN",
      label: "安能"
    },
    {
      value: "EMS",
      label: "邮政EMS"
    },
    {
      value: "JT",
      label: "极兔"
    },
    {
      value: "ZY",
      label: "众邮"
    },
    {
      value: "FW",
      label: "丰网"
    },
    {
      value: "COM",
      label: "其它"
    }
  ],
  placeholder: {
    'yto': {
      code: '圆通工号',
      name: '业务员名称',
      pass: '尊者密码（PDA密码）',
      phone: '',
      areaCode: '',
      branches: '圆通网点编码',
      deviceModel: '巴枪/工业机设备型号',
      branchesName: '',
      sn: '巴枪/工业机设备号',
      XZPass: '行者密码',
    },
    'sto': {
      code: '申通工号',
      name: '业务员名称',
      pass: '申通密码',
      phone: '',
      areaCode: '',
      branches: '申通网点编号',
      branchesName: '',
      sn: '申通巴枪设备号',
      XZPass: '申行者密码',
    },
    'yd': {
      code: '韵达工号',
      name: '业务员名称',
      pass: '韵达口令',
      phone: '韵达绑定手机号',
      areaCode: '',
      branches: '韵达网点编码',
      branchesName: '',
      sn: '巴枪/工业机设备号',
      YBXPass: "韵镖侠密码"
    },
    'bs': {
      code: '百世工号',
      name: '业务员名称',
      pass: '如来神掌密码',
      phone: '百世绑定手机号',
      phonePass: '手机登录密码',
      areaCode: '业务员三段码',
      branches: '百世网点编码',
      branchesName: '',
      sn: '手机MEID号'
    },
    'tt': {
      code: '天天工号',
      name: '业务员名称',
      pass: '苏宁APP密码',
      phone: '天天绑定手机号',
      areaCode: '',
      branches: '天天网点编码(KT+8位数编号)',
      branchesName: '',
      sn: '苏宁APP登录页面设备号'
    },
    'zt': {
      code: '中通工号',
      name: '业务员名称',
      pass: 'PDA密码',
      phone: '中通绑定手机号',
      areaCode: '',
      branches: '中通网点编码',
      branchesName: '',
      sn: '中通巴枪设备号',
      ZZTPass: '掌中通密码',
      manufacturer: '中通巴枪厂商'
    },
    'uc': {
      code: '优速手机号',
      name: '业务员名称',
      pass: '优速宝登录密码',
      phone: '优速绑定手机号',
      areaCode: '',
      branches: '优速网点编码',
      branchesName: '',
      sn: '优速宝APP中的设备编码'
    },
    'an': {
      code: '安能员工编号',
      name: '点击获取自动录入',
      pass: '安能巴枪的登录密码',
      phone: '',
      areaCode: '',
      branches: '安能所属网点编号',
      branchesName: '点击获取自动录入',
      sn: '安能PDA的设备号'
    },
    'ems': {
      code: '',
      name: '',
      pass: '',
      phone: '',
      areaCode: '',
      branches: '',
      branchesName: '',
      sn: ''
    },
    'jt': {
      code: '',
      name: '',
      pass: '',
      branches: ''
    },'zy': {
      code: '',
      name: '',
      pass: '',
      branches: ''
    },'fw': {
      code: '丰火APP账号',
      name: '业务员名称',
      pass: '丰火APP密码'
    }
  },
//ip: process.env.NODE_ENV == 'production' ? location.protocol + '//' + location.host : 'http://oms.aixunmiao.com',
  // ip: 'http://test.aixunmiao.com',
    		ip: 'http://192.168.0.41:8998',
  //       ip: 'http://192.168.0.130:9999',
  //				ip: "http://192.168.0.171:9999",
  //		 ip: "http://192.168.1.2:9999",
  rechargeType: [{
      label: "银联",
      value: 1
    },
    {
      label: "微信",
      value: 2
    },
    {
      label: "支付宝",
      value: 3
    },
    {
      label: "现金",
      value: 4
    },
    {
      label: "其他",
      value: 5
    }
  ],
  storageAppList: [{
      value: "SLQ",
      label: "来取",
    },
    {
      value: "SXH",
      label: "星火",
    },
    {
      value: "SMM",
      label: "妈妈驿站"
    }
  ],
  errorTypeList: ['快递总部API返回错误码', '自定义异常', '其他异常'],
  nodeEnv: function (url) {
    if (process.env.NODE_ENV === "development"&&url.indexOf("http") == -1) {
      return "/api" + url;
    } else {
      return url;
    }
  },
  isEmpty: function (data) {
    if (!data) {
      if (typeof data == "number") {
        return false;
      }
      return true;
    } else {
      return false;
    }
  },
  setTextarea: function (str) {
    str = str.replace(/,+|，+/g, ' ');
    str = str.replace(/\s+/g, ' ');
    var list = str.split(' ');
    return list;
  },
  billChargeType: [{
      value: 1,
      label: '充值'
    },
    {
      value: 2,
      label: '调整单'
    },
    {
      value: 3,
      label: '代收'
    },
    {
      value: 4,
      label: '到付'
    },
    {
      value: 5,
      label: '出货'
    },
    {
      value: 6,
      label: '推荐'
    },
    {
      value: 7,
      label: '物料'
    },
    {
      value: 8,
      label: '中转'
    },
    {
      value: 9,
      label: '退件'
    },
  ],
  mes: {
    1: {
      title: '是否通过这些申请？',
      message: '已通过申请!'
    },
    2: {
      title: '是否拒绝这些申请？',
      message: '已拒绝申请!'
    },
    3: {
      title: '确认入账？',
      message: '已入账！'
    }
  },
  consumptioTypeList: [{
      label: '充值',
      value: 1,
      color: '#409EFF'
    },
    {
      label: '消费',
      value: 2,
      color: '#F56C6C'
    }
  ],
  balanceTypeList: [{
      label: '收入',
      value: 1,
      color: '#409EFF'
    },
    {
      label: '支出',
      value: 2,
      color: '#F56C6C'
    }
  ],
  costStatusList: [{
      label: '待支付',
      btnName: '去支付',
      value: 0,
      color: '#F56C6C'
    },
    {
      label: '已支付',
      btnName: false,
      value: 1,
      color: '#409EFF'
    },
    {
      label: '待确认',
      btnName: '确认账单',
      value: 2,
      color: '#409EFF'
    },
    {
      label: '发票索取',
      btnName: '详情',
      value: 5,
      color: '#409EFF'
    },
    {
      label: '发票寄出',
      btnName: '详情',
      value: 6,
      color: '#67C23A'
    },
    {
      label: '无状态',
      value: '--',
      color: '#409EFF'
    }
  ],
  channelList: [{
      label: '账户金额',
      value: 1
    },
    {
      label: '支付宝',
      value: 2
    },
    {
      label: '微信',
      value: 3
    },
    {
      label: '银联',
      value: 4
    },
    {
      label: '后端操作',
      value: 5
    },
  ],
  excuteStatusList: [{
      label: '等待处理',
      value: 0,
      color: '#409EFF'
    },
    {
      label: '处理成功',
      value: 1,
      color: '#67C23A'
    },
    {
      label: '处理失败',
      value: 2,
      color: '#F56C6C'
    },
    {
      label: '正在处理',
      value: 9,
      color: '#409EFF'
    }
  ],
  scanTypeList: [{
      value: 1,
      label: '到件'
    },
    {
      value: 2,
      label: '派件'
    },
    {
      value: 3,
      label: '签收'
    },
    {
      value: 4,
      label: '发往'
    },
    {
      value: 5,
      label: '到派合一'
    },
    {
      value: 6,
      label: '到发合一'
    },
    {
      value: 7,
      label: '问题件'
    },
    {
      value: 10,
      label: '新派件'
    },
    {
      value: 11,
      label: '虚拟到件'
    },
    {
      value: 13,
      label: '揽收'
    },
    {
      value: 96,
      label: '单号识别'
    },
    {
      value: 97,
      label: '派签汇总'
    },
    {
      value: 98,
      label: '派签'
    },
    {
      value: 99,
      label: '异常信息'
    },
  ],
  oprateScanTypeList1: [{
    label: '到派合一',
    value: 5
  }, {
    label: '到件',
    value: 1
  }, {
    label: '派件',
    value: 2
  }],
  oprateScanTypeList2: [{
    label: '到发合一',
    value: 6
  }, {
    label: '到件',
    value: 1
  }, {
    label: '发往',
    value: 4
  }],
  deliveryBillstatuses: [{
    label: '已结算',
    value: 1,
    color: '#67C23A'
  }, {
    label: '未结算',
    value: 2,
    color: '#F56C6C'
  }],
  paymentFlagList: [{
    label: '未结算',
    value: 0,
    color: '#F56C6C'
  }, {
    label: '已结算',
    value: 1,
    color: '#67C23A'
  }],
  cloneObj: function (source) {
    function clone(source) {
      var sourceCopy = {};
      for (var item in source) sourceCopy[item] = typeof source[item] === 'object' ? clone(source[item]) : source[item];
      return sourceCopy;
    }
    return clone(source);
  }
};
